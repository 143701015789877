<template>
  <div class="page-content">
    <page-breadcrumb v-if="group" title="Trigger Group" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1" v-if="group">
        <form-generator :model="group" :schema="group_form_schema" />
      </b-card>
    </section>

    <section class="mb-2" v-if="group">
      <h2 class="mb-2">Triggers In This Group</h2>
      <data-table-ssr id="trigger_list" ref="trigger_list" :limit-base="36" :pagination="false"
        :columns="trigger_fields" :get-list-fn="getTriggers"
        :to-edit-fn="toEditPage" :delete-row-fn="deletetrigger"
      />
      <div class="d-flex justify-content-center my-2">
        <data-create-popup title="Create Trigger" :schema="trigger_create_schema"
          :default-data="{ key: null, description: null, trigger_once: false }"
          :create-data-fn="createTrigger" @create="reloadTriggerList"
        />
      </div>
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service_group';
import triggerService from '../service';
import { OVERLAP_TYPE_OPTIONS } from '../constant';

const group_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Name', field: 'name', input_type: 'text', validate: { required: true }  },
      { label: 'Description', field: 'description', input_type: 'textarea' },
      { label: 'Overlap Type', field: 'overlap_type', input_type: 'select', options: OVERLAP_TYPE_OPTIONS },
    ]
  }
];

const trigger_create_schema = [
  {
    cols: 12,
    fields: [
      {
        label: 'Key', field: 'key', input_type: 'text',
        validate: { type: 'string', required: true, to_snake: true }
      },
      { label: 'Description', field: 'description', input_type: 'textarea' },
      { label: 'Recover Days', field: 'recover_time', input_type: 'number', validate: { type: 'number', min: 0 } },
      // { label: 'Trigger Once', field: 'trigger_once', input_type: 'switch',  validate: { type: 'boolean', default: false }  },
    ],
  }
];
const trigger_fields = [
  { label: 'Key', field: 'key' },
  { label: 'Description', field: 'description', editable: true },
  { label: 'Recover Days', field: 'recover_time', input_type: 'number', validate: { type: 'number', min: 0 } },
  // { label: 'Trigger Once', field: 'trigger_once', input_type: 'switch', editable: true },
  { label: 'Created At', field: 'createdAt', input_type: 'datetime' },
];

export default {
  data(){
    return{
      group_form_schema,
      trigger_fields,
      trigger_create_schema,
      group: null,
      isUpdating: false,
      isDeleting: false,
      triggers: null,
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Noti Groups',
          to: { name: 'noti-group-list' },
        },
        {
          text: `${this.group ? this.group.name : this.itemId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    this.getGroup();
  },
  methods:{
    // group
    async getGroup(){
      this.group = await service.get({id: this.itemId});
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.group),
      });
      this.getGroup();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "noti-group-list" });
    },
    // trigger
    async createTrigger(data) {
      let new_item = await triggerService.create({ ...data, group_id: this.group._id });
      return new_item;
    },
    async getTriggers({ limit, page, query, searchTerm }){
      let data = await triggerService.getList({
        query: JSON.stringify({ ...query, group_id: this.itemId }),
        limit, page
      });
      let list = [], total = 0;
      if(data) {
        list = data.list;
        total = data.total;
      }
      return { list, total };
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'noti-trigger-detail', params: { trigger_id: _id } });
    },
    reloadTriggerList() {
      this.$refs.trigger_list.getList();
    },
    async edittrigger(row) {
      await triggerService.update({ data: JSON.stringify(row) });
    },
    async deletetrigger({ _id }) {
      await triggerService.delete({ id: _id });
      this.reloadTriggerList();
    },
  }
}
</script>
